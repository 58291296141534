@import 'variables.less';

.moreTabScroll{
  display: flex;
  flex-wrap: wrap;
    .customerMaintab{
        width: calc(~"100% - 80px");
    border-bottom: none;
    padding: 0 5px;
  }
}
.configurationWrapper{
  display: block;
  width: 100%;
  border-top: 1px solid #efefef;
  padding-top: 15px;
}

.antwrap-process-setting-wrapper {
  display: flex;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  .date-wrapper {
    width: 50%;
    padding: 40px;
    border-right: 1px solid #e9e9e9;
    height: 100%;
  }
  .heading {
    color: #424242;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0 0 20px 0;
  }
  .uploadButton {
    background-color: #000 !important;
    border: none !important;
    border-radius: 10px !important;
    color: #fff !important;
    display: block !important;
    margin-top: 10px !important;
  }
  .disableUploadButton {
    background-color: #e0e0e1 !important;
    color: #acacac !important;
  }
  .file-upload-div-wrapper {
    padding: 40px;
    width: 50%;
    .upload-wrapper {
      border: 1px solid #e9e9e9;
      border-radius: 10px;
      width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      flex-direction: column;
      cursor: pointer;
      img {
        width: 40px;
      }
      .title {
        margin-bottom: 0;
        margin-top: 10px;
        font-weight: 600;
        color: #000;
        font-size: 16px;
      }
      .discription {
        color: #999;
        line-height: 24px;
      }
    }
    .file-delete-wrapper {
      gap: 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
      p {
        margin-bottom: 0 !important;
      }
      img {
        width: 17px;
        cursor: pointer;
      }
    }
  }
}
